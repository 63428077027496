import './styles/App.css';
import React from "react";
import WalletUi from './components/WalletUi';

const OPENSEA_LINK = "https://opensea.io/collection/solar-by-martin-houra";
const CONTRACT_ADDRESS = "0x215c65cb28665b123B255179f8D2f385FAD73B55";
const NAME = "Solar";
const SYMBOL = "SOLAR";
const PRICE = "0.35";

const SIZE = "400";

const generateRandomNumber = (min, max) =>  {
  return Math.round(Math.random() * (max - min) + min);
};
console.log(`What is ${generateRandomNumber}?`);

const RandomNumber01 = () =>  {
  return Math.round(Math.random() * (15 - 1) + 1);
};
console.log(`RandomNumber01 is ${RandomNumber01}?`);


const App = () => {
  
  return (
    <div>
      <div className="container">
        <img className="solar_teaser pt40 pb40" src="Solar_teaser.gif" width="400px" height="400px" alt="Solar"></img>
        <div className="header gradient-header pb40">SOLAR</div>
        <div className="gradient-text">
          The bubbling, the gurgling, the burning of retinas. Moving with force, power, energy. Swimming beneath and within the surface pools of colour drip, swirl and shift.
          <br /><br />
          SOLAR is a collection of 100 videos on a custom made smart contract, living on the Ethereum blockchain, forever.
          <br /><br />
          Contract: <a href={"https://etherscan.io/address/"+CONTRACT_ADDRESS+"#code"} target="_blank" rel="noreferrer">View on Etherscan</a><br />
          Token tracker: <a href={"https://etherscan.io/token/"+CONTRACT_ADDRESS } target="_blank" rel="noreferrer">{NAME} ({SYMBOL})</a><br />
          Total Supply: 100<br />
          ERC-721
          <br /><br />
          View on <a href={OPENSEA_LINK} target="_blank" rel="noreferrer">OpenSea</a>
          <br /><br />
          Info: 15s, 1920x1920px, 30fps, H.264
        </div>
        <WalletUi contract={CONTRACT_ADDRESS} price={PRICE} />
        <hr class="hr_yellow" />
        <div>
          <div className="gallery gradient-header pb40 pt40">Gallery</div>
          <div className="gradient-text">
            Preview of 6 randomly selected animations from the full set, in no particular order.
          </div>
          <div>
            <video width={SIZE} height={SIZE} autoPlay loop preload="true" muted class="solar_preview" poster="https://martinhoura.net/media/loading.png">
              <source src={`https://martinhoura.net/media/` + generateRandomNumber(1, 16) + `.mp4`} />
            </video>
            <video width={SIZE} height={SIZE} autoPlay loop preload="true" muted class="solar_preview" poster="https://martinhoura.net/media/loading.png">
              <source src={`https://martinhoura.net/media/` + generateRandomNumber(17, 32) + `.mp4`} />
            </video>
            <video width={SIZE} height={SIZE} autoPlay loop preload="true" muted class="solar_preview" poster="https://martinhoura.net/media/loading.png">
              <source src={`https://martinhoura.net/media/` + generateRandomNumber(33, 48) + `.mp4`} />
            </video>
            <video width={SIZE} height={SIZE} autoPlay loop preload="true" muted class="solar_preview" poster="https://martinhoura.net/media/loading.png">
              <source src={`https://martinhoura.net/media/` + generateRandomNumber(49, 64) + `.mp4`} />
            </video>
            <video width={SIZE} height={SIZE} autoPlay loop preload="true" muted class="solar_preview" poster="https://martinhoura.net/media/loading.png">
              <source src={`https://martinhoura.net/media/` + generateRandomNumber(65, 80) + `.mp4`} />
            </video>
            <video width={SIZE} height={SIZE} autoPlay loop preload="true" muted class="solar_preview" poster="https://martinhoura.net/media/loading.png">
              <source src={`https://martinhoura.net/media/` + generateRandomNumber(81, 100) + `.mp4`} />
            </video>
          </div>
        </div>
        <div class="gradient-text pb80">
          <div>
            © <a href="https://martinhoura.net/" target="_blank" rel="noreferrer" class="footerLink">martinhoura.net</a>
          </div>
        </div>

      </div>

      <div className="footer-container"></div>
    </div>
  );
};

export default App;