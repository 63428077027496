import '../styles/App.css';
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Solar from "../utils/Solar.json"; // Contract's ABI

const MAX_SUPPLY = 100;

const App = (props) => {
    const [wallet, setWallet] = useState("");
    const [contract, setContract] = useState(0);
    const [mintingActive, setMintingActive] = useState("");
    const [tokenCount, setTokenCount] = useState(null);
    const [tokenLimit, setTokenLimit] = useState(null);
    const PRICE = props.price;
    
    const checkIfWalletIsConnected = async () => {
        const { ethereum } = window; 
        if (!ethereum) {
            console.log("Make sure you have MetaMask!");
            return;
        } else {
            console.log("We have the ethereum object", ethereum);
        }
  
        const accounts = await ethereum.request({ method: 'eth_accounts' });
  
        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log("Found an authorized account:", account);
                      setWallet(account)
            connectWallet();
        } else {
            console.log("No authorized account found")
        }
        let chainId = await ethereum.request({ method: 'eth_chainId' });
        console.log("Connected to chain " + chainId);
  
        // String, hex code of the chainId of the Ethereum Mainnet network
        // Change this for running via Testnet
        // 0x1 - mainnet
        // 0x4 - rinkeby
        const mainnetChainId = "0x1"; 
        if (chainId !== mainnetChainId) {
          alert("You are not connected to the Ethereum Mainnet!");
        }
    }

    const connectWallet = async () => {
        try {
            const { ethereum } = window;
            if (!ethereum) {
            alert("Get MetaMask!");
            return;
            }
            const accounts = await ethereum.request({ method: "eth_requestAccounts" });
            console.log("Connected", accounts[0]);
            setWallet(accounts[0]);
            connectContract(); 
        } catch (error) {
            console.log(error)
        }
    }

    const connectContract = async () => {
        try {
            const { ethereum } = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(props.contract, Solar.abi, signer);
                
                setContract(connectedContract);
                
                await connectedContract.mintActive().then(result=> {
                    if(result){
                         console.log("mint Active: "+result);
                         setMintingActive(result);
                    }
                })
                console.log("Setup minting contract");

            } else {
                console.log("Ethereum object doesn't exist!");
            }
            } catch (error) {
                console.log(error)  
            }
    }

    const askContractToMintNft = async () => {
        try {
          const { ethereum } = window;

          if (ethereum && contract) {
          

            let nftTxn = await contract.mint({value: ethers.utils.parseEther(PRICE)});
            console.log("Minting...please wait.")
        
            await nftTxn.wait();           
            console.log(`Minted, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);

          } else {
            console.log("Ethereum object doesn't exist!");
          }
        } catch (error) {
          console.log(error)
        }
    }

    const getBalance = async () => {
        const { ethereum } = window;

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(props.contract, Solar.abi, signer);

        try {
          const [_tokenCount] = await Promise.all([connectedContract.totalSupply()]);
          const tokenCount = ethers.BigNumber.from(_tokenCount).toNumber();
          const tokenLimit = MAX_SUPPLY;
          setTokenCount(tokenCount);
          setTokenLimit(tokenLimit);
          console.log({ tokenCount, tokenLimit });
        } catch (error) {
          console.log('Caught an error while fetching collection data.');
          console.log(error);
        }
    }

    useEffect(()=>{
        checkIfWalletIsConnected();
        connectContract();
        getBalance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  
    const tokenRemaining = tokenLimit - tokenCount;
    const loaded = tokenLimit !== null && tokenCount !== null;

    return (               
        <div className="mint-container">
         { (wallet === "") ?   (          
            <button onClick={connectWallet} className="cta-button connect-wallet-button">
                Connect to Wallet
            </button>
        ) : (mintingActive ? 
            (
            <div>
                <button onClick={askContractToMintNft} className="cta-button mint-button">
                    Mint {props.price} ETH
                </button>
                <p className='mint-count pb40'>
                {loaded ? `${tokenCount} of ${tokenLimit} minted, ${tokenRemaining ? `only ${tokenRemaining} left.` : `they are all gone!`}` : '...'}
                </p>
            </div>
            ) :(
                <button className="cta-button no-mint-button">
                    Mint Not Active
                </button>
            )
        )}
        { (wallet === "") ? (<p className="wallet-connect">No wallet connected</p>) : (<p className="wallet-connect">Connected to wallet {wallet}</p>)}
        
        </div>
    );
};

export default App;

